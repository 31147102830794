/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  SetStateAction,
  Dispatch,
  useMemo,
} from 'react';

import * as uuid from 'uuid';
import { differenceWith, isEqual } from 'lodash';

import { storages } from '~/constants/storages';

import { TProductComboItem } from '~/components/Products/ComboJourneyModal';

import { loadProduct } from '~/services/products';

import { IProduct } from '~/interfaces/IProduct';
import { ICartTotals } from '~/interfaces/ICartTotals';
import { ICartProduct } from '~/interfaces/ICartProduct';
import { ICartAdditional } from '~/interfaces/ICartAdditional';

import { useStore } from './store';
import { useFacebookPixel } from './facebookPixel';

export interface IEditingProduct extends ICartProduct {
  indexInCart?: number;
}

export interface IProductComboItemCart {
  product: IProduct;
  quantity: number;
  comboJourneyId: string;
}
interface ICartContextData {
  cart: ICartProduct[];
  setCart: Dispatch<SetStateAction<ICartProduct[]>>;
  addProductToCart(
    product: ICartProduct,
    productsComboList?: {
      product: IProduct;
      quantity: number;
      additionals: ICartAdditional[];
      comboJourneyId: string;
    }[]
  ): void;
  updateProductToCart(product: ICartProduct): void;
  removeProductToCart(product: ICartProduct): void;
  cleanTheProductInTheCart(product: ICartProduct): void;
  cartTotals: ICartTotals;
  editingProduct: IEditingProduct;
  setEditingProduct: Dispatch<SetStateAction<IEditingProduct>>;
  cleanCart(): void;

  selectedComboProduct: IProduct | undefined;
  setSelectedComboProduct: (
    comboProduct: IProduct | undefined,
    isEditing?: boolean,
    productListEditing?: ICartProduct[],
    productQuantity?: number
  ) => void;
  editingComboList: TProductComboItem[] | undefined;
}

const CartContext = createContext({} as ICartContextData);

const CartProvider: FC = ({ children }) => {
  const { store } = useStore();
  const { track } = useFacebookPixel();

  const [cart, setCart] = useState<ICartProduct[]>([]);

  const [editingProduct, setEditingProduct] = useState<IEditingProduct>(null);

  const [selectedComboProduct, _setSelectedComboProduct] = useState<
    IProduct | undefined
  >(undefined);

  const [editingComboList, setEditingComboList] = useState<
    TProductComboItem[] | undefined
  >(undefined);

  const setSelectedComboProduct = useCallback(
    async (
      comboProduct: IProduct | undefined,
      isEditing?: boolean,
      productListEditing?: ICartProduct[],
      productQuantity?: number
    ): Promise<void> => {
      if (
        comboProduct &&
        (comboProduct?.comboJourneys || []).every(
          (journey) => journey.type === 'automatic'
        )
      ) {
        const listProducts = [];

        for (const journey of comboProduct?.comboJourneys || []) {
          for (const prod of journey.products) {
            listProducts.push({
              product: {
                ...prod,
                comboValue: prod.priceInCombo,
              },
              quantity: prod.quantity || 1,
              comboJourneyId: '',
            });
          }
        }

        addProductToCart(
          {
            _id: comboProduct._id,
            tempId: uuid.v4(),
            name: comboProduct.name,
            description: comboProduct.description,
            price: comboProduct.comboValue,
            promotionalPrice: comboProduct?.promotionalPrice || 0,
            dimensions: comboProduct.dimensions,
            pictures: comboProduct.pictures,
            quantity: productQuantity || 1,
            baseQuantity: 1,
            additionals: [],
          },
          listProducts
        );
      } else {
        if (isEditing) {
          const formattedItemsForEditing: TProductComboItem[] =
            await Promise.all(
              productListEditing.map(async (item) => {
                const productFound = await loadProduct(item._id);

                return {
                  product: {
                    _id: item._id,
                    product: { ...productFound },
                    priceInCombo: item.price,
                    quantity: item.quantity,
                    pictures: item.pictures,
                    name: item.name,
                  },
                  quantity: item.quantity,
                  baseQuantity: item.baseQuantity,
                  comboJourneyId: item.comboJourneyId,
                  additionals: item.additionals,
                };
              })
            );

          setEditingComboList(formattedItemsForEditing);
        }
        _setSelectedComboProduct(comboProduct);
      }
    },
    []
  );

  const verifyAdditionalsInProduct = useCallback(
    (product: ICartProduct, productInCart: ICartProduct): boolean => {
      let additionalIsEquals = false;
      const additionalDifferenceArr = differenceWith(
        productInCart.additionals.map((add) => {
          return { ...add, options: [] };
        }),
        product.additionals.map((add) => {
          return { ...add, options: [] };
        }),
        isEqual
      );

      if (
        (!additionalDifferenceArr || additionalDifferenceArr.length <= 0) &&
        productInCart.additionals.length === product.additionals.length
      ) {
        additionalIsEquals = true;

        for (
          let index2 = 0;
          index2 < productInCart.additionals.length;
          index2++
        ) {
          const findAdditional = product.additionals.find(
            (add) => add._id === productInCart.additionals[index2]._id
          );

          if (
            findAdditional.options.length !==
            productInCart.additionals[index2].options.length
          ) {
            additionalIsEquals = false;
          }

          if (findAdditional) {
            const optionsDifferenceArr = differenceWith(
              findAdditional.options,
              productInCart.additionals[index2].options,
              isEqual
            );

            if (optionsDifferenceArr && optionsDifferenceArr.length > 0) {
              additionalIsEquals = false;
            }
          }
        }
      }

      return additionalIsEquals;
    },
    []
  );

  const verifyIfExistProductOnCart = useCallback(
    (product: ICartProduct): number => {
      const cartEqualProducts = cart.filter(
        (cartProduct) =>
          cartProduct._id === product._id && cartProduct.note === product.note
      );

      if (cartEqualProducts && cartEqualProducts.length > 0) {
        for (let index = 0; index < cartEqualProducts.length; index++) {
          const isAdditionalsEquals = verifyAdditionalsInProduct(
            product,
            cartEqualProducts[index]
          );

          if (isAdditionalsEquals) {
            if (product?.items && product.items?.length > 0) {
              const comboItemsDifferenceArr = differenceWith(
                product.items.map((comboItem) => {
                  return { product: comboItem._id, subitems: comboItem?.items?.map((subitem) => subitem) };
                }),
                cartEqualProducts[index]?.items?.map((comboItemCart) => {
                  return { product: comboItemCart._id, subitems: comboItemCart?.items?.map((subitem) => subitem) };
                }),
                isEqual
              );

              if (
                (!comboItemsDifferenceArr ||
                  comboItemsDifferenceArr.length <= 0) &&
                cartEqualProducts[index].items.length === product.items.length
              ) {
                const arrVerifyAddComboItems = product.items.map(
                  (comboItem) => {
                    const isAdditionalsComboItemEquals =
                      verifyAdditionalsInProduct(
                        comboItem,
                        cartEqualProducts[index].items.find(
                          (comboItemInCart) =>
                            (comboItemInCart._id === comboItem._id) && (comboItemInCart.comboJourneyId === comboItem.comboJourneyId)
                        )
                      );

                      if (isAdditionalsComboItemEquals) {
                        return true;
                      }
                      return false;
                    }
                  );

                  if (arrVerifyAddComboItems.every((item) => item === true)) {
                  const findProductIndex = cart.findIndex(
                    (cartProductI) =>
                      cartProductI.tempId === cartEqualProducts[index].tempId &&
                      cartProductI.note === cartEqualProducts[index].note
                  );

                  return findProductIndex;
                }
              }
            } else {
              const findProductIndex = cart.findIndex(
                (cartProductI) =>
                  cartProductI.tempId === cartEqualProducts[index].tempId &&
                  cartProductI.note === cartEqualProducts[index].note
              );

              return findProductIndex;
            }
          }
        }
      }

      return -1;
    },
    [cart]
  );

  const addProductToCart = useCallback(
    (
      product: ICartProduct,
      productsComboLits?: {
        product: IProduct;
        quantity: number;
        baseQuantity: number;
        additionals: ICartAdditional[];
        comboJourneyId: string;
      }[]
    ): void => {
      const newProduct = { ...product };

      if(productsComboLits) {
        const formattedComboProducts = productsComboLits.map(
          ({
            product: comboProduct,
            quantity,
            additionals,
            comboJourneyId,
            baseQuantity,
          }) => ({
            _id: comboProduct._id || comboProduct.product?._id,
            tempId: uuid.v4(),
            name: comboProduct.name || comboProduct.product?.name,
            description: comboProduct.description || comboProduct.product?.description,
            price: comboProduct.comboValue,
            promotionalPrice: product?.promotionalPrice || 0,
            dimensions: product.dimensions,
            pictures: product.pictures,
            quantity: quantity || 1,
            additionals: additionals || [],
            comboJourneyId,
          })
        );

        newProduct.items = formattedComboProducts
      }

      const productIndex = verifyIfExistProductOnCart(newProduct);
      const newCart = [...cart];

      const newQuantity =
        productIndex >= 0
          ? editingProduct?.indexInCart >= 0
            ? product.quantity
            : newCart[productIndex].quantity + product.quantity
          : product.quantity;

      if (productsComboLits) {

        const comboProductsUnitPriceTotal =
          newProduct?.items?.reduce((acc, comboProduct) => {
            const additionalsTotal = (comboProduct.additionals || []).reduce(
              (additionalsAcc, additional) => {
                const optionsTotal = (additional.options || []).reduce(
                  (optionsAcc, option) =>
                    optionsAcc + option.price * option.quantity,
                  0
                );
                return additionalsAcc + optionsTotal;
              },
              0
            );
            const fullPrice =
              (comboProduct.price + additionalsTotal) * comboProduct.quantity;

            return acc + fullPrice;
          }, 0);

        newProduct.price = comboProductsUnitPriceTotal;
      }

      if (productIndex >= 0) {
        if (editingProduct?.indexInCart >= 0) {
          newCart[productIndex] = {
            ...newProduct,
            quantity: newProduct.quantity,
          };

          setEditingComboList(undefined);
          setEditingProduct(null);
        } else {
          newCart[productIndex] = {
            ...newCart[productIndex],
            items: newProduct.items.map((item) => ({...item, quantity: item.quantity + newProduct.quantity})),
            quantity: newCart[productIndex]?.quantity + newProduct.quantity,
          };
        }

        setCart(newCart);
        localStorage.setItem(
          storages.CART(store?.tag),
          JSON.stringify(newCart || [])
        );
      } else if (editingProduct?.indexInCart >= 0) {
        newCart[editingProduct.indexInCart] = {
          ...newProduct,
          quantity: newProduct.quantity,
        };

        setCart(newCart);
        localStorage.setItem(
          storages.CART(store?.tag),
          JSON.stringify(newCart)
        );

        setEditingComboList(undefined);
        setEditingProduct(null);
      } else {
        setCart((prevState) => [{ ...newProduct }, ...prevState]);
        localStorage.setItem(
          storages.CART(store?.tag),
          JSON.stringify([{ ...newProduct }, ...cart])
        );
      }

      track('AddToCart');
    },
    [cart, store?.tag, track, verifyIfExistProductOnCart, editingProduct]
  );

  const updateProductToCart = useCallback(
    (product: ICartProduct) => {
      const productIndex = verifyIfExistProductOnCart(product);

      const newCart = [...cart];

      if (productIndex >= 0) {
        newCart[productIndex] = {
          ...newCart[productIndex],
          quantity: product.quantity,
        };
      } else {
        newCart[editingProduct.indexInCart] = product;
      }

      setCart(newCart);
      localStorage.setItem(
        storages.CART(store?.tag),
        JSON.stringify(newCart || [])
      );
    },
    [cart, editingProduct, store?.tag, verifyIfExistProductOnCart]
  );

  const removeProductToCart = useCallback(
    (product: ICartProduct) => {
      const productForRemove = cart.find(
        (cartProduct) => cartProduct.tempId === product.tempId
      );

      if (productForRemove.quantity === 1) {
        const newCart = cart.filter(
          (cartProduct) => cartProduct.tempId !== product.tempId
        );

        setCart(newCart);
        localStorage.setItem(
          storages.CART(store?.tag),
          JSON.stringify(newCart)
        );
      } else {
        const newCart = [...cart];
        const productIndex = cart.findIndex(
          (cartProduct) => cartProduct.tempId === product.tempId
        );

        const newQuantity = newCart[productIndex].quantity - 1;

        newCart[productIndex] = {
          ...newCart[productIndex],
          items: (newCart[productIndex].items || []).map((item) => ({
            ...item,
            quantity: (item.baseQuantity || item.quantity) - 1,
          })),
          quantity: newQuantity,
        };

        setCart(newCart);
        localStorage.setItem(
          storages.CART(store?.tag),
          JSON.stringify(newCart)
        );
      }
    },
    [cart, store?.tag]
  );

  const cleanTheProductInTheCart = useCallback(
    (product: ICartProduct) => {
      const productIndex = verifyIfExistProductOnCart(product);

      const newCart = [...cart];
      newCart.splice(productIndex, 1);

      setCart(newCart);
      localStorage.setItem(storages.CART(store?.tag), JSON.stringify(newCart));
    },
    [cart, store?.tag, verifyIfExistProductOnCart]
  );

  const cleanCart = useCallback(() => {
    localStorage.removeItem(storages.CART(store?.tag));

    setCart([]);
  }, [store?.tag]);

  const cartTotals: ICartTotals = useMemo(() => {
    const quantityTotal = cart.reduce((acc, item) => acc + item.quantity, 0);

    const total = cart.reduce((acc, item) => {
      const additionalsTotal = (item.additionals || []).reduce(
        (additionalsAcc, additional) => {
          const optionsTotal = (additional.options || []).reduce(
            (optionsAcc, option) => optionsAcc + option.price * option.quantity,
            0
          );
          return additionalsAcc + optionsTotal;
        },
        0
      );

      return acc + (item.price + additionalsTotal) * item.quantity;
    }, 0);

    return {
      price: total,
      quantity: quantityTotal,
    };
  }, [cart]);

  useEffect(() => {
    if (!cart || cart.length <= 0) {
      const storagedCart = localStorage.getItem(storages.CART(store?.tag));

      const parsedCart = JSON.parse(storagedCart || '[]');

      if (parsedCart && parsedCart.length > 0) {
        setCart(parsedCart);
      }
    }
  }, [cart, store?.tag]);

  return (
    // @ts-ignore
    <CartContext.Provider
      value={{
        cart,
        cartTotals,
        editingProduct,
        editingComboList,
        selectedComboProduct,
        setSelectedComboProduct,
        setCart,
        addProductToCart,
        updateProductToCart,
        removeProductToCart,
        cleanTheProductInTheCart,
        setEditingProduct,
        cleanCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = (): ICartContextData => {
  return useContext(CartContext);
};

export { CartProvider, useCart };
